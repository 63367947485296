import _cloneDeep from 'lodash/cloneDeep';

export function getSingleSignOnConfigurationToCreate(newSingleSignOnConfiguration) {
  const data = _cloneDeep(newSingleSignOnConfiguration);

  data.selectedProviderAdminRoles = data.selectedProviderAdminRoles.map((role) => role.value);
  data.selectedProviderViewerRoles = data.selectedProviderViewerRoles.map((role) => role.value);
  data.selectedProviderServiceDeskRoles = data.selectedProviderServiceDeskRoles.map((role) => role.value);

  return data;
}

export function getSingleSignOnConfigurationToUpdate(newSingleSignOnConfiguration) {
  const data = _cloneDeep(newSingleSignOnConfiguration);

  data.selectedProviderAdminRoles = data.selectedProviderAdminRoles
    .map((role) => (role.value ? role.value : role));
  data.selectedProviderViewerRoles = data.selectedProviderViewerRoles
    .map((role) => (role.value ? role.value : role));
  data.selectedProviderServiceDeskRoles = data.selectedProviderServiceDeskRoles
    .map((role) => (role.value ? role.value : role));

  return data;
}
